import React, { Fragment } from "react";

import translate from "../services/translate";

const AboutContent = () => {
  return (
    <Fragment>
      <h1>Off</h1>
      <p>{translate("About.paragraphOne")}</p>
      <p>{translate("About.paragraphTwo")}</p>
      <p>{translate("About.parahgraphTwoPointFive")}</p>
      <p>{translate("About.paragraphThree")}</p>
      <p>{translate("About.paragraphFour")}</p>
      <p>{translate("About.paragraphFive")}</p>
      <p>{translate("About.paragraphSix")}</p>
      <p>{translate("About.paragraphSeven")}</p>
    </Fragment>
  );
};

export default AboutContent;
