import React from "react";
import PropTypes from "prop-types";

import "../styles/Link.css";

const Link = (props) => {
  return (
    <a className="link" href={props.url}>
      {props.children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

export default Link;
