import React from "react";
import PropTypes from "prop-types";

import "../styles/Blackout.css";

const Blackout = ({ onClick }) => {
  return <div className="blackout" onClick={onClick}></div>;
};

Blackout.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Blackout;
