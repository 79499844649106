import React, { Fragment } from "react";
import PropTypes from "prop-types";

import edition from "../assets/edition.json";
import "../styles/StatsBar.css";

const StatsBar = ({ property, trueClassName, falseClassName }) => {
  let count = 0;

  return (
    <Fragment>
      <table className="statsbar">
        <tbody>
          <tr key={"item"}>
            {edition.off.map((item) => {
              if (item[property] === true) {
                count += 1;
                return <td className={trueClassName} key={item}></td>;
              }
              return <td className={falseClassName} key={item}></td>;
            })}
          </tr>
        </tbody>
      </table>
      <p className="underStats">
        {count}/255 {property}
      </p>
    </Fragment>
  );
};

StatsBar.propTypes = {
  falseClassName: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  trueClassName: PropTypes.string.isRequired,
};

export default StatsBar;
