import React from "react";
import PropTypes from "prop-types";

const Menu = ({ open }) => {
  return open ? (
    <div className="menuItems">
      <a href="/about">About</a>
      <a href="/">Collection</a>
      <a href="/leaderboard">Leaderboard</a>
      <a href="/opensea">Enable Opensea</a>
      <a href="/faq">FAQ</a>
    </div>
  ) : (
    <div className="menuItems menuClosed">
      <a href="/about">About</a>
      <a href="/">Collection</a>
      <a href="/leaderboard">Leaderboard</a>
      <a href="/opensea">Enable Opensea</a>
      <a href="/faq">FAQ</a>
    </div>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Menu;
