import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./views/Home";
import About from "./views/About";
import FAQ from "./views/FAQ";
import Leaderboard from "./views/Leaderboard";
import OpenSea from "./views/OpenSea";
import Gallery from "./views/Gallery";
import Token from "./views/Token";

const SessionContainer = ({ component: Component }) => {
  return <Component />;
};

const PublicRoute = ({ component, path }) => {
  return (
    <Route path={path}>
      <SessionContainer component={component} />
    </Route>
  );
};

// prettier-ignore
const Routes = () => (
  <Switch>
    <PublicRoute
      component={Home}
      exact
      path="/"
    />
    <PublicRoute
      component={About}
      exact
      path="/about"
    />
    <PublicRoute
      component={FAQ}
      exact
      path="/faq"
    />
    <PublicRoute
      component={Leaderboard}
      exact
      path="/leaderboard"
    />
    <PublicRoute
      component={OpenSea}
      exact
      path="/opensea"
    />
    <PublicRoute
      component={Gallery}
      exact
      path="/gallery"
    />
    <PublicRoute
      component={Token}
      exact
      path="/:id"
    />
  </Switch>
);

SessionContainer.propTypes = {
  component: PropTypes.elementType.isRequired,
  isTokenRequired: PropTypes.bool,
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

export default Routes;
