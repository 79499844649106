import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getToken, getPrice, TX_BUY } from "../services/contracts/off";
import { ZERO_ADDRESS } from "../services/contracts";
import { getRequest } from "../store/api/actions";
import { useRequestId } from "../hooks/request";
import translate from "../services/translate";
import edition from "../assets/edition.json";
import { usePendingTransaction } from "../hooks/ethereum";
import { enableAccount } from "../store/ethereum/actions";
import Modal from "./Modal";
import Buyable from "./Buyable";
import Subscribe from "./Subscribe";
import Link from "./Link";
import "../styles/Detail.css";

const Detail = ({ tokenId }) => {
  const dispatch = useDispatch();
  const [localTokenData, setLocalTokenData] = useState({});
  const [tokenData, setTokenData] = useState({});
  const [price, setPrice] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buyable, setBuyable] = useState(false);
  const [buying, setBuying] = useState(false);
  const ipfsRequestId = useRequestId();
  const { account, chainId, hasProvider } = useSelector(
    (state) => state.ethereum
  );

  useEffect(() => {
    const thisItem = edition.off.find((element) => element.token_id == tokenId);
    setLocalTokenData(thisItem);
    setIsLoading(false);
  }, [setLocalTokenData, tokenId]);

  const startBuyFlow = () => {
    setBuying(true);
  };

  const stopBuying = () => {
    setBuying(false);
  };

  const openModal = () => {
    setSubscribing(true);
  };

  const doneSubscribing = () => {
    setSubscribing(false);
  };

  const onEnable = (event) => {
    event.preventDefault();
    dispatch(enableAccount());
  };

  const buyTx = usePendingTransaction({
    txMethod: TX_BUY,
    params: { tokenId, account },
  });

  useEffect(() => {
    const getTokenData = async () => {
      const token = await getToken(tokenId);
      const p = await getPrice();

      setPrice(p);
      setTokenData(token);
      setBuyable(
        token.forSale && token.owner === process.env.REACT_APP_OFF_OWNER
      );

      dispatch(
        getRequest({
          id: ipfsRequestId,
          endpoint: token.tokenUri,
        })
      );
    };

    getTokenData();
  }, [
    setTokenData,
    setIsLoading,
    tokenId,
    dispatch,
    ipfsRequestId,
    buyTx.isPending,
  ]);

  return !isLoading ? (
    <div>
      <div className="imageContainer">
        <img
          src={require(`../assets/images/${localTokenData.name}.png`).default}
        />
      </div>
      <div className="infoContainer">
        <h1 className="detailText">{localTokenData.name}</h1>

        <p className="detailText">
          {translate("Detail.blurb", { name: localTokenData.name })}
        </p>
        {buyable ? (
          <Fragment>
            {buying ? (
              <Buyable
                name={localTokenData.name}
                stopBuying={stopBuying}
                tokenId={tokenId}
              />
            ) : (
              <Fragment>
                <p className="detailText">
                  {translate("Detail.buyableDescription")}
                </p>
                <p className="detailText">
                  {translate("Detail.price", { price })}
                </p>
                {hasProvider ? (
                  !account ? (
                    <button onClick={onEnable}>Connect</button>
                  ) : chainId !== process.env.REACT_APP_CHAINID ? (
                    <p className="detailText error">
                      {translate("Header.wrongNetwork")}
                    </p>
                  ) : (
                    <Fragment>
                      <p className="detailText">
                        {translate("Header.connected")}
                        <span className="available">{account}</span>
                      </p>
                      <button className="buyButton" onClick={startBuyFlow}>
                        Buy
                      </button>
                    </Fragment>
                  )
                ) : null}
              </Fragment>
            )}
          </Fragment>
        ) : tokenData.owner !== process.env.REACT_APP_OFF_OWNER &&
          tokenData.owner !== ZERO_ADDRESS ? (
          <Fragment>
            <p className="detailText">
              {translate("Detail.alreadySoldDescription")}
            </p>
            <p className="detailText">Current owner: {tokenData.owner}</p>
          </Fragment>
        ) : (
          <Fragment>
            <p className="detailText">
              {translate("Detail.notForSaleDescription")}
            </p>
            <button className="subscribeButton" onClick={openModal}>
              Subscribe for Updates
            </button>
            {subscribing ? (
              <Modal close={doneSubscribing}>
                <Subscribe />
              </Modal>
            ) : null}
          </Fragment>
        )}

        <p className="detailText">
          {translate("Detail.aboutTeaser")}
          <Link url="/about">{translate("Detail.aboutLink")}</Link>
        </p>
      </div>
    </div>
  ) : null;
};

Detail.propTypes = {
  tokenId: PropTypes.string.isRequired,
};

export default Detail;
