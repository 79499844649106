import Joi from "joi";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import translate from "../services/translate";

import InputField from "./InputField";
import InputHiddenField from "./InputHiddenField";
import web3Validators from "../helpers/web3Validators";
import Link from "./Link";

const schema = {
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  address: web3Validators.web3().address().required(),
  tokenId: Joi.number().integer().positive().required(),
  country: Joi.string().required(),
};

const FormEmail = (props) => {
  return (
    <Fragment>
      <p>
        {translate("FormEmail.description")}
        <Link url="">{translate("FormEmail.descriptionLink")}</Link>
      </p>
      <p>{translate("FormEmail.emailDisclaimer")}</p>
      <InputField
        label={translate("FormEmail.fieldEmail")}
        name="email"
        type="email"
        validate={schema.email}
      />
      <InputField
        label={translate("FormEmail.fieldCountry")}
        name="country"
        type="country"
        validate={schema.country}
      />
      <p>{translate("FormEmail.countryDisclaimer")}</p>
      <InputHiddenField
        label={"tokenId"}
        name={"tokenId"}
        value={{ value: props.tokenId }}
      />
      <InputHiddenField
        label={"address"}
        name={"address"}
        value={{ value: props.address }}
      />
    </Fragment>
  );
};

FormEmail.propTypes = {
  address: PropTypes.string.isRequired,
  tokenId: PropTypes.number.isRequired,
};

export default FormEmail;
