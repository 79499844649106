import React, { Fragment } from "react";

import Link from "./Link";
import translate from "../services/translate";

const FAQContent = () => {
  return (
    <Fragment>
      <h1>FAQ</h1>
      <h2>{translate("FAQ.questionOne")}</h2>
      <p>{translate("FAQ.answerOne")}</p>
      <h2>{translate("FAQ.questionTwo")}</h2>
      <p>
        {translate("FAQ.answerTwo")}
        <Link url="https://wallet.matic.network/bridge/">
          {translate("FAQ.answerTwoLink")}
        </Link>
        {translate("FAQ.answerTwoPointFive")}
      </p>
      <h2>{translate("FAQ.questionThree")}</h2>
      <p>
        {translate("FAQ.answerThree")}
        <Link url="https://cryptoticker.io/en/add-polygon-matic-network-metamask/">
          {translate("FAQ.answerThreeLink")}
        </Link>
        {translate("FAQ.answerThreePointFive")}
      </p>
      <h2>{translate("FAQ.questionFour")}</h2>
      <p>{translate("FAQ.answerFour")}</p>
      <h2>{translate("FAQ.questionFive")}</h2>
      <p>{translate("FAQ.answerFive")}</p>
      <h2>{translate("FAQ.questionSix")}</h2>
      <p>{translate("FAQ.answerSix")}</p>
      <h2>{translate("FAQ.questionSeven")}</h2>
      <p>{translate("FAQ.answerSeven")}</p>
      <h2>{translate("FAQ.questionEight")}</h2>
      <p>
        {translate("FAQ.answerEight")}
        <Link url="https://github.com/ana0/off-decode/">
          {translate("FAQ.answerEightLink")}
        </Link>
      </p>
      <h2>{translate("FAQ.questionNine")}</h2>
      <p>{translate("FAQ.answerNine")}</p>
      <h2>{translate("FAQ.questionTen")}</h2>
      <p>{translate("FAQ.answerTen")}</p>
      <h2>{translate("FAQ.questionEleven")}</h2>
      <p>{translate("FAQ.answerEleven")}</p>
      <h2>{translate("FAQ.questionTwelve")}</h2>
      <p>
        {translate("FAQ.answerTwelve")}
        <Link url="http://eepurl.com/hy_2lD">
          {translate("FAQ.answerTwelveLink")}
        </Link>
        {translate("FAQ.answerTwelvePointFive")}
      </p>
      <h2>{translate("FAQ.questionThirteen")}</h2>
      <p>{translate("FAQ.answerThirteen")}</p>
      <h2>{translate("FAQ.questionFourteen")}</h2>
      <p>
        {translate("FAQ.answerFourteen")}
        <Link url="https://polygonscan.com/address/0xe87f9b37ee17e188cb56a02e190a26c532a4bb31">
          {translate("FAQ.answerFourteenLink")}
        </Link>
        {translate("FAQ.answerFourteenPartTwo")}
      </p>
      <h2>{translate("FAQ.questionFifteen")}</h2>
      <p>
        {translate("FAQ.answerFifteen")}
        <Link url="https://off.supply/opensea">
          {translate("FAQ.answerFifteenLink")}
        </Link>
      </p>
      <h2>{translate("FAQ.questionSeventeen")}</h2>
      <p>{translate("FAQ.answerSeventeen")}</p>
    </Fragment>
  );
};

export default FAQContent;
