import React, { Fragment } from "react";

import View from "../components/View";
import Header from "../components/Header";
import TextContent from "../components/TextContent";
import OpenSeaIntegration from "../components/OpenSeaIntegration";

const HowtoUse = () => {
  return (
    <Fragment>
      <View>
        <Header />
        <TextContent>
          <OpenSeaIntegration />
        </TextContent>
      </View>
    </Fragment>
  );
};

export default HowtoUse;
