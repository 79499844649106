import React, { Fragment, useEffect, useState } from "react";

import StatsBar from "../components/StatsBar";
import translate from "../services/translate";
import edition from "../assets/edition.json";

import "../styles/Gallery.css";

const Stats = () => {
  const [shared, setShared] = useState(0);
  const [sold, setSold] = useState(0);

  useEffect(() => {
    let tempShared = 0;
    let tempSold = 0;
    edition.off.map((item) => {
      if (item.sold === true) {
        tempSold += 1;
      }
      if (item.shared === true) {
        tempShared += 1;
      }
    });
    const tempSoldDiv3 = tempSold / 3;
    const tempSharedDiv = tempShared / tempSoldDiv3;
    const rounded = Math.round((tempSharedDiv + Number.EPSILON) * 100) / 100;
    setSold(3);
    setShared(rounded);
  }, [setSold, setShared]);

  return (
    <Fragment>
      <p className="leaderboard">{translate("Stats.intro")}</p>
      <p className="leaderboard">
        {translate("Stats.paragraph", { shared, sold })}
      </p>
      <StatsBar falseClassName="unsold" property="sold" trueClassName="sold" />
      <StatsBar
        falseClassName="unshared"
        property="shared"
        trueClassName="shared"
      />
    </Fragment>
  );
};

export default Stats;
