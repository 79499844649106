import { offContract, metamaskOffContract } from "./";
import web3 from "../web3";

export const TX_BUY = Symbol("TX_BUY");
export const TX_APPROVE = Symbol("TX_APPROVE");

export async function getTokenUri(tokenId) {
  return await offContract.methods.tokenURI(tokenId).call();
}

export async function getToken(tokenId) {
  const token = await offContract.methods.getToken(tokenId).call();
  return {
    tokenUri: token[0],
    forSale: token[1],
    secretImageHash: token[2],
    imageHash: token[3],
    owner: token[4],
  };
}

export async function getPrice() {
  return web3.utils.fromWei(await offContract.methods.price().call());
}

export async function isForSale(tokenId) {
  return await offContract.methods.forSale(tokenId).call();
}

export async function buy(tokenId, user, issuingTime, nonce, auth) {
  const { transactionHash: txHash } = await metamaskOffContract.methods
    .buy(tokenId, user, issuingTime, nonce, auth)
    .send({
      from: user,
      value: web3.utils.toWei("31.5"),
    });
  return { txHash, txMethod: TX_BUY };
}

export async function approveOpenSeaForAll(user) {
  console.log(user); // eslint-disable-line
  const {
    transactionHash: txHash,
  } = await metamaskOffContract.methods
    .setApprovalForAll("0x58807baD0B376efc12F5AD86aAc70E78ed67deaE", true)
    .send({
      from: user,
    });
  return { txHash, txMethod: TX_APPROVE };
}
