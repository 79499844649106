import React, { Fragment, useEffect } from "react";

import Stats from "../components/Stats";
import Blackout from "../components/Blackout";
import View from "../components/View";
//import translate from "../services/translate";

import "../styles/Gallery.css";

const Gallery = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 3600000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <View>
        <Blackout />
        <div className="galleryWrapper">
          <h1 className="galleryHeader">Off Leaderboard</h1>
          <Stats />
        </div>
      </View>
    </Fragment>
  );
};

export default Gallery;
