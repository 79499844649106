import React from "react";
import { useHistory } from "react-router-dom";

import TableItem from "./TableItem";
import edition from "../assets/edition.json";
import "../styles/Table.css";

const Table = () => {
  const history = useHistory();

  const onClick = (id) => {
    history.push(`${id}`);
  };

  const first = [];
  const second = [];
  const third = [];

  const buildArrays = () => {
    let firstStart = 85;
    let secondStart = 3;
    let thirdStart = 172;
    for (let i = 0; i < 21; i++) {
      first.push(firstStart);
      firstStart += 4;
      if (i != 20) {
        second.push(secondStart);
        secondStart += 4;
      }
      third.push(thirdStart);
      thirdStart += 4;
    }
  };

  buildArrays();

  // {Array.from(Array(Math.ceil(edition.off.length / 4) + 1).keys()).map(
  return (
    <table className="hometable">
      <tbody>
        {first.map((item) => {
          const current = item;
          return (
            <tr key={item}>
              {current < edition.off.length && (
                <TableItem
                  name={edition.off[current].name}
                  tokenId={edition.off[current].token_id}
                  onClick={() => onClick(edition.off[current].token_id)}
                />
              )}
              {current + 1 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 1].name}
                  tokenId={edition.off[current + 1].token_id}
                  onClick={() => onClick(edition.off[current + 1].token_id)}
                />
              )}
              {current + 2 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 2].name}
                  tokenId={edition.off[current + 2].token_id}
                  onClick={() => onClick(edition.off[current + 2].token_id)}
                />
              )}
              {current + 3 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 3].name}
                  tokenId={edition.off[current + 3].token_id}
                  onClick={() => onClick(edition.off[current + 3].token_id)}
                />
              )}
            </tr>
          );
        })}

        <tr key={170}>
          <TableItem
            name={edition.off[169].name}
            tokenId={edition.off[169].token_id}
            onClick={() => onClick(edition.off[169].token_id)}
          />
          <TableItem
            name={edition.off[0].name}
            tokenId={edition.off[0].token_id}
            onClick={() => onClick(edition.off[0].token_id)}
          />
          <TableItem
            name={edition.off[1].name}
            tokenId={edition.off[1].token_id}
            onClick={() => onClick(edition.off[1].token_id)}
          />
          <TableItem
            name={edition.off[2].name}
            tokenId={edition.off[2].token_id}
            onClick={() => onClick(edition.off[2].token_id)}
          />
        </tr>

        {second.map((item) => {
          const current = item;
          return (
            <tr key={item}>
              {current < edition.off.length && (
                <TableItem
                  name={edition.off[current].name}
                  tokenId={edition.off[current].token_id}
                  onClick={() => onClick(edition.off[current].token_id)}
                />
              )}
              {current + 1 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 1].name}
                  tokenId={edition.off[current + 1].token_id}
                  onClick={() => onClick(edition.off[current + 1].token_id)}
                />
              )}
              {current + 2 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 2].name}
                  tokenId={edition.off[current + 2].token_id}
                  onClick={() => onClick(edition.off[current + 2].token_id)}
                />
              )}
              {current + 3 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 3].name}
                  tokenId={edition.off[current + 3].token_id}
                  onClick={() => onClick(edition.off[current + 3].token_id)}
                />
              )}
            </tr>
          );
        })}

        <tr key={83}>
          <TableItem
            name={edition.off[83].name}
            tokenId={edition.off[83].token_id}
            onClick={() => onClick(edition.off[83].token_id)}
          />
          <TableItem
            name={edition.off[84].name}
            tokenId={edition.off[84].token_id}
            onClick={() => onClick(edition.off[84].token_id)}
          />
          <TableItem
            name={edition.off[170].name}
            tokenId={edition.off[170].token_id}
            onClick={() => onClick(edition.off[170].token_id)}
          />
          <TableItem
            name={edition.off[171].name}
            tokenId={edition.off[171].token_id}
            onClick={() => onClick(edition.off[171].token_id)}
          />
        </tr>

        {third.map((item) => {
          const current = item;
          return (
            <tr key={item}>
              {current < edition.off.length && (
                <TableItem
                  name={edition.off[current].name}
                  tokenId={edition.off[current].token_id}
                  onClick={() => onClick(edition.off[current].token_id)}
                />
              )}
              {current + 1 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 1].name}
                  tokenId={edition.off[current + 1].token_id}
                  onClick={() => onClick(edition.off[current + 1].token_id)}
                />
              )}
              {current + 2 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 2].name}
                  tokenId={edition.off[current + 2].token_id}
                  onClick={() => onClick(edition.off[current + 2].token_id)}
                />
              )}
              {current + 3 < edition.off.length && (
                <TableItem
                  name={edition.off[current + 3].name}
                  tokenId={edition.off[current + 3].token_id}
                  onClick={() => onClick(edition.off[current + 3].token_id)}
                />
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
