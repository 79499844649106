import React, { Fragment } from "react";

import View from "../components/View";
import Table from "../components/Table";
import Header from "../components/Header";

const Home = () => {
  return (
    <Fragment>
      <View>
        <Header />
        <Table />
      </View>
    </Fragment>
  );
};

export default Home;
