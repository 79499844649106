import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "../styles/TableItem.css";
import { isForSale } from "../services/contracts/off";
import translate from "../services/translate";

const TableItem = ({ onClick, tokenId, name }) => {
  const [forSale, setForSale] = useState(false);

  useEffect(() => {
    const getForSaleStatus = async () => {
      const forSaleStatus = await isForSale(tokenId);
      setForSale(forSaleStatus);
    };

    getForSaleStatus();
  }, [setForSale, tokenId]);

  return (
    <td className={forSale ? "forSale" : "notForSale"}>
      <div className="previewContainer" onClick={onClick}>
        <img
          className="imgPreview"
          src={require(`../assets/images/${name}.png`).default}
        />
      </div>
      <div className="buyContainer">
        <p>{name}</p>
        {forSale && (
          <p className="available">{translate("TableItem.available")}</p>
        )}
        <button className="viewButton" onClick={onClick}>
          View
        </button>
      </div>
    </td>
  );
};

TableItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tokenId: PropTypes.number.isRequired,
};

export default TableItem;
