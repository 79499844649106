import React, { Fragment } from "react";

const Subscribe = () => {
  return (
    <Fragment>
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      />
      <div id="mc_embed_signup">
        <form
          action="https://supply.us6.list-manage.com/subscribe/post?u=66047f008de54871f5f34d19e&amp;id=6ad217733a"
          className="validate"
          id="mc-embedded-subscribe-form"
          method="post"
          name="mc-embedded-subscribe-form"
          noValidate
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe</h2>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                className="required email"
                defaultValue=""
                id="mce-EMAIL"
                name="EMAIL"
                type="email"
              />
            </div>
            <div className="clear" id="mce-responses">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                defaultValue=""
                name="b_66047f008de54871f5f34d19e_6ad217733a"
                tabIndex="-1"
                type="text"
              />
            </div>
            <div className="clear">
              <input
                className="button"
                id="mc-embedded-subscribe"
                name="subscribe"
                style={{ fontFamily: "inherit" }}
                type="submit"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Subscribe;
