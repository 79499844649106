import React from "react";
import PropTypes from "prop-types";

import "../styles/TextContent.css";

const TextContent = (props) => {
  return <div className="content">{props.children}</div>;
};

TextContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextContent;
