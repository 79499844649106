import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import translate from "../services/translate";
import { enableAccount } from "../store/ethereum/actions";
import { usePendingTransaction } from "../hooks/ethereum";
import { approveOpenSeaForAll, TX_APPROVE } from "../services/contracts/off";
import { addPendingTransaction } from "../store/ethereum/actions";
import Link from "./Link";
import "../styles/OpenSea.css";

const OpenSeaIntegration = () => {
  const dispatch = useDispatch();
  const { account, chainId, hasProvider } = useSelector(
    (state) => state.ethereum
  );
  const [approved, setApproved] = useState(false);
  //const [isError, setIsError] = useState(false);

  const onEnable = (event) => {
    event.preventDefault();
    dispatch(enableAccount());
  };

  const approveTx = usePendingTransaction({
    txMethod: TX_APPROVE,
    params: { account },
  });

  useEffect(() => {
    if (approveTx.isSuccess) {
      setApproved(true);
    }
  }, [approveTx.isPending, setApproved, approveTx.isSuccess]);

  const onClickApprove = async (user) => {
    console.log(user); // eslint-disable-line
    const { txHash, txMethod } = await approveOpenSeaForAll(user);
    dispatch(
      addPendingTransaction({
        txHash,
        txMethod,
        params: { account },
      })
    );
  };

  return (
    <Fragment>
      <h1>Enable OpenSea</h1>
      <p>{translate("OpenSea.paragraphOne")}</p>
      <p>{translate("OpenSea.paragraphTwo")}</p>
      {approved ? (
        <p>You have approved OpenSea</p>
      ) : hasProvider ? (
        !account ? (
          <button className="openseaButton" onClick={onEnable}>
            Connect
          </button>
        ) : chainId !== process.env.REACT_APP_CHAINID ? (
          <p className="detailText error">{translate("Header.wrongNetwork")}</p>
        ) : (
          <Fragment>
            <p className="detailText">
              {translate("Header.connected")}
              <span className="available">{account}</span>
            </p>
            <button
              className="openseaButton"
              onClick={() => onClickApprove(account)}
            >
              Confirm
            </button>
          </Fragment>
        )
      ) : null}
      <p>
        {translate("OpenSea.paragraphThree")}
        <Link url="https://polygonscan.com/address/0xe87f9b37ee17e188cb56a02e190a26c532a4bb31">
          {translate("OpenSea.paragraphThreeLink")}
        </Link>
        {translate("OpenSea.paragraphThreePostLink")}
      </p>
    </Fragment>
  );
};

export default OpenSeaIntegration;
