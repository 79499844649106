import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from "react-redux";

import Routes from "./routes";
import "./styles/App.css";
import {
  initializeProvider,
  checkPendingTransactions,
} from "./store/ethereum/actions";

let checkInterval;

const App = () => {
  const dispatch = useDispatch();

  const CHECK_FREQUENCY = 1000;

  useEffect(() => {
    const initialize = async () => {
      await dispatch(initializeProvider());
    };
    initialize();
  }, [dispatch]);

  checkInterval = window.setInterval(() => {
    dispatch(checkPendingTransactions());
  }, CHECK_FREQUENCY);

  window.addEventListener("unload", () => {
    window.clearInterval(checkInterval);
  });

  return (
    <Fragment>
      <Router>
        <Routes />
      </Router>
    </Fragment>
  );
};

export default App;
