import React, { Fragment } from "react";

import Stats from "../components/Stats";
import View from "../components/View";
import Header from "../components/Header";

import "../styles/Leaderboard.css";

const Leaderboard = () => {
  return (
    <Fragment>
      <View>
        <Header />
        <h1 className="leaderboardHeader">Leaderboard</h1>
        <Stats />
      </View>
    </Fragment>
  );
};

export default Leaderboard;
