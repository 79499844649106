import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

import "../styles/Header.css";
import hamburger from "../assets/interface/hamburger.svg";
import Menu from "./Menu";
// import { enableAccount } from "../store/ethereum/actions";
import translate from "../services/translate";
import Modal from "./Modal";
import Subscribe from "./Subscribe";

const Header = () => {
  const [open, setOpen] = useState(false);
  // const dispatch = useDispatch();
  const [subscribing, setSubscribing] = useState(false);
  // const { account, chainId, hasProvider } = useSelector(
  //   (state) => state.ethereum
  // );

  // const onEnable = (event) => {
  //   event.preventDefault();
  //   dispatch(enableAccount());
  // };

  const openMenu = () => {
    setOpen(!open);
  };

  const openModal = () => {
    setSubscribing(true);
  };

  const doneSubscribing = () => {
    setSubscribing(false);
  };

  return (
    <div className="header">
      <img className="menu" src={hamburger} onClick={openMenu} />
      <Menu open={open} />
      <button onClick={openModal}>Subscribe for Updates</button>
      {subscribing ? (
        <Modal close={doneSubscribing}>
          <Subscribe />
        </Modal>
      ) : null}
      <p className="message">{translate("Header.welcome")}</p>
    </div>
  );
};

export default Header;
