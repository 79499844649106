const components = {
  About: {
    paragraphOne:
      "Off is an artist edition, artwork, and multiplayer game by Sarah Friend.",
    paragraphTwo: `Using this site, the NFTs that encompass the artist edition can 
      be collected. The first batch of 85 dropped on June 11th, and the second batch 
      will be announced later this Summer`,
    parahgraphTwoPointFive: `These images correspond to the exact pixel dimensions of a large collection 
      of computer monitors, smart phones, and tablets. Each NFT has two components: a 
      public image, and a secret image. The public images are displayed on this website 
      - the secret images are distributed to collectors by email.`,
    paragraphThree: `Within each secret image is hidden two things: an encrypted 
      sentence and a shard of the private key that was used to encrypt it. 
      This is done using a form of steganography. Across the full edition of 
      255 images, an entire essay and the entire private key are hidden. A majority of 
      the private key shards (2/3) are required to decrypt any of the sentences, 
      therefore the essay can only be read if a majority of collectors 
      collaborate to share their images.`,
    paragraphFour: `When an NFT is purchased, the collector will be transferred the NFT and 
      emailed the secret image along with further game instructions. 
      The metadata of the NFT contains the hash of both the public and the secret 
      image, so that it can be validated and its authenticity guaranteed.`,
    paragraphFive: `Each NFT is issued from a fully-compliant EIP721 contract 
      on Polygon, a POS-based chain that is compatible with Opensea. They will all be sold at 
      a flat rate of 32 MATIC or 35 USD, but these numbers may adjust if the MATIC:USD 
      ratio changes significantly. You will be able to buy them directly 
      through this site with MATIC, or you can 
      email sarahfriend (at) isthisa.com to pay with another method (xdai, paypal, etc)`,
    paragraphSix: `Think of this as a multiplayer game that you are playing with 
      the other purchasers of this artist edition, or perhaps, a massively 
      multiplayer prisoner's dilemma. Will you choose to cooperate or defect?`,
    paragraphSeven: "After 170 are sold, the rules change.",
  },
  Detail: {
    blurb: "A solid black image in the pixel dimensions of the {name}",
    preBuy: `This artwork is not yet available.`,
    aboutTeaser: `Like all the pieces in Off, 
      this piece has two components: a public image, and a secret one. The public image is 
      shown here now, the secret one will only be revealed to the collector.  `,
    aboutLink: "Read More",
    buyableDescription: "This artwork is still available",
    notForSaleDescription: "This artwork is not available",
    alreadySoldDescription: "This artwork has already been sold",
    emailError: `Could not validate!`,
    emailError2: `You have probably either submitted too many requests or 
      already purchased more than the limit of 5 tokens per email. If you haven't purchased more than 5 tokens, 
      try coming back in an hour or two. If the problem persists, contact support`,
    price: "Price: {price} MATIC or 35 USD",
  },
  Buyable: {
    confirmation: "{name} has been transferred to {account}",
    confirmation2:
      "You will receive the secret image at {email} within the next 24 hours",
    pending: "Waiting ...",
    error: "Something went wrong ...",
    youCanBuyNow: "Your details have been successfully validated.",
    youCanBuyNow2: "Proceed to purchase {name}?",
  },
  FAQ: {
    questionOne: "How do I buy an NFT from this website?",
    answerOne: `You can buy an NFT from this website using MATIC, the native token 
      of Polygon network. You will also need Metamask configured for Polygon network. 
      Paying with fiat is on the roadmap, but not currently possible through the site. For now, to 
      pay with other means, contact sarahfriend@isthisa.com. For now, NFT sales 
      are limited to 5 per email address.`,
    questionTwo: "How do I get MATIC?",
    answerTwo: `The quickest way is probably to buy MATIC on uniswap, then move it over the bridge to Polygon mainnet. `,
    answerTwoLink: "Here is the bridge. ",
    answerTwoPointFive: `If you're familiar with xDai and the xDai bridge, this works 
      similarly. You can also buy MATIC from vendors like Moonpay. If you're trying to 
      buy MATIC and it's not appearing in your wallet the way you expect, you may be on 
      the wrong network. Check for both the MATIC erc20 token on Ethereum mainnet and 
      the native token of Polygon network.`,
    questionThree: "How do I use Polygon with Metamask?",
    answerThree: "You can add Polygon as a custom network pretty easily. ",
    answerThreeLink: "Here is a good tutorial. ",
    answerThreePointFive:
      "Once added, you will have the same address on both networks and can easily switch between them.",
    questionFour: "Why is Off on Polygon?",
    answerFour: `The are three main reasons this project is on Polygon: low transaction 
      fees, low energy usage of the network, and compatibility with Opensea. Given the 
      low price point of this edition, it's not a good fit for mainnet, where sometimes 
      a single transaction fee is more than the price of an Off NFT. The debate around 
      energy use and blockchains is complicated and ongoing, but in the simplest case 
      when it is possible to avoid proof of work and its energy consumption, I think one 
      should do so. Launching Off on PoS was a way to demonstrate that this is easier than many people may think. 
      And finally, being compatible with Opensea gives Off collectors an 
      alternative interface to view their NFTs, or to migrate them between wallets if 
      needed. Yes, despite all this, it would be easier for many people to interact with 
      Off in many ways if it were on mainnet. It was not an easy decision.`,
    questionFive: "How can I tell I received my Off NFT?",
    answerFive: `Off is compatible with Opensea, if you make an account there, you 
      should see it in your collection. Alternately, the Off website displays the 
      current owner of each NFT on its individual page.`,
    questionSix: "What does the secret image look like?",
    answerSix:
      "The secret image looks exactly the same as the public image - at least to the human eye.",
    questionSeven: "What is hidden in the secret image?",
    answerSeven:
      "One encrypted sentence from a text, and one shard of the private key used to encrypt it.",
    questionEight: "How do I decrypt the secret?",
    answerEight: `You need to gather 170 (2/3) of the secret images from the collection. 
      The best way of doing this is probably by collaborating with the other 
      collectors, though there are of course other ways. Once they are in one place, 
      you can run the `,
    answerEightLink: "decryption script",
    questionNine: "How do collector's coordinate?",
    answerNine: `There are two main ways for collectors to coordinate in the first 
      phase of the game. The first is the collector's email list: this is made up of 
      all previous purchasers and sent to the collector after their purchase. The other 
      is the collector's-only telegram chat.`,
    questionTen: "What's going on in the collector's chat?",
    answerTen: "Sorry, I can tell you. Why don't you buy an NFT and find out?",
    questionEleven: "How long until the secrets are revealed?",
    answerEleven: `It won't be possible to reveal the secrets until 170 works from Off 
      are sold, or that is, if/when the second batch sells out. Before then, there won't 
      be enough secret images in circulation. The earliest this will possibly happen is 
      later this Summer.`,
    questionTwelve: "Where can I get updates about this project?",
    answerTwelve: "For now, the best way is to join ",
    answerTwelveLink: "the mailing list. ",
    answerTwelvePointFive:
      "Soon, I will be adding a leaderboard to this site to track progress of how many are sold and how many are shared.",
    questionThirteen: "When is the next drop?",
    answerThirteen: `It's not yet scheduled, but it will be in July or August. 
      The date will be posted in the header for this site and on the mailing list as 
      soon as it's selected.`,
    questionFourteen:
      "If I bought one of the Off NFTs on a secondary market, how can I be sure I have the right secret image?",
    answerFourteen: `The hash of each secret image is stored directly in the Off smart 
      contract, so they can be compared directly. The easiest way to check this right now is by using polygonscan, where 
      the `,
    answerFourteenLink: `Off contracts are verified`,
    answerFourteenPartTwo: `. The method that returns the hash of the secret image is called secretImageHash, and 
      takes the token number as input. If you bought 
      an Off NFT on secondary market and didn't receive the secret image, I will ask you 
      to sign a message with the private key of the current NFT owner. If the signature 
      checks out, I will send you the secret image.`,
    questionFifteen: "Why can't I Sell/Accept a bid on opensea?",
    answerFifteen: `Unfortunately, there is an incompatibility between the Off contracts and OpenSea's
      expectations. They expect their contract address to be automatically whitelisted/approved for all NFTs, but this 
      not the case in the Off contracts. I apologize for the inconvenience - it was difficult to test for this 
      since Off launched before OpenSea's polygon integration went live. There is helper page to enable OpenSea `,
    answerFifteenLink: "here",
    questionSeventeen: "What can you tell us about the rule change?",
    answerSeventeen: "Nothing.",
  },
  FormEmail: {
    description: `We collect your email so that we can send you the secret image 
      associated with this NFT. To find out more, `,
    emailDisclaimer: `By buying this NFT, you also agree to your email address being shared with all future purchasers of Off NFTs`,
    descriptionLink: "read about this project",
    fieldEmail: "Email: ",
    fieldCountry: "Country: ",
    countryDisclaimer:
      "(Unfortunately, our accounting requirements need to know whether you're based in the EU)",
  },
  Header: {
    connected: "Currently connected as: ",
    welcome: " ",
    wrongNetwork: "Please switch Metamask to Polygon network",
  },
  Gallery: {
    paragraphOne: `Off is an artwork, artist edition, and multiplayer game that encompasses 255 solid black images,
      corresponding to the pixel dimensions of various popular screens. Hidden across the entire edition, is 
      an encrypted text and the secret key that can decrypt it. The key is hidden in such a way that it 
      can only be revealed if 170 (2/3rds) of the images are combined. Each collector must choose: will they 
      collaborate, and share their image, or defect?`,
  },
  OpenSea: {
    paragraphOne: `This page allows you to enable OpenSea. Unfortunately, there 
      is a minor incompatibility between the Off contracts and OpenSea's expectations, meaning you need 
      to manually approve the OpenSea contract address before you can use their marketplace for your Off NFTs.`,
    paragraphTwo: `You will need to do this to sell your NFTs in their marketplace, or to accept existing bids`,
    paragraphThree: `You can also do this on polygonscan if you prefer. The `,
    paragraphThreeLink: `Off contracts `,
    paragraphThreePostLink: `are verified, 
      and the method that needs to be called is setApprovalForAll. The address to approve is 0x58807baD0B376efc12F5AD86aAc70E78ed67deaE. 
      Do not approve any other addresses except at your own risk.`,
  },
  TableItem: {
    defaultText: "An item in the table",
    available: "Available",
  },
  Stats: {
    intro:
      "The text can only be decrypted when 170 secrets are shared, representing 2/3 of the total edition.",
    paragraph: `The current ratio of shared/sold is {shared}/{sold}.`,
  },
};

const views = {
  Home: {},
};

export default {
  title: "Off",
  default: {
    buttonSubmit: "Submit",
  },
  ...components,
  ...views,
};
