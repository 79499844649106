import React, { Fragment } from "react";

import View from "../components/View";
import Header from "../components/Header";
import TextContent from "../components/TextContent";
import AboutContent from "../components/AboutContent";

const About = () => {
  return (
    <Fragment>
      <View>
        <Header />
        <TextContent>
          <AboutContent />
        </TextContent>
      </View>
    </Fragment>
  );
};

export default About;
