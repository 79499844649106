import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import View from "../components/View";
import Detail from "../components/Detail";
import Header from "../components/Header";

const Token = () => {
  const { id } = useParams();

  return (
    <Fragment>
      <View>
        <Header />
        <Detail tokenId={id} />
      </View>
    </Fragment>
  );
};

export default Token;
