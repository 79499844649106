import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import translate from "../services/translate";
import { useRequestForm } from "../hooks/forms";
import { putRequest } from "../store/api/actions";
import { useRequestId, useRequest } from "../hooks/request";
import { addPendingTransaction } from "../store/ethereum/actions";
import { usePendingTransaction } from "../hooks/ethereum";
import { buy, TX_BUY } from "../services/contracts/off";
import Modal from "./Modal";
import FormEmail from "./FormEmail";
import ButtonSubmit from "./ButtonSubmit";
import "../styles/Detail.css";

const Buyable = ({ name, tokenId, stopBuying }) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.ethereum);
  const emailRequestId = useRequestId();
  const [buyToken, setBuyToken] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState();

  const { Form } = useRequestForm({
    onSubmit: ({ email, address, tokenId, country }) => {
      setEmail(email);
      dispatch(
        putRequest({
          id: emailRequestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: ["buyers"],
          body: {
            email,
            address,
            tokenId,
            country,
          },
        })
      );
    },
  });

  useRequest(emailRequestId, {
    onError: () => {
      setIsEmailError(true);
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setIsEmailError(true);
      } else {
        setBuyToken(response.data);
      }
    },
  });

  const onClickBuy = async ({ auth, issuingTime, nonce }, user) => {
    const { txHash, txMethod } = await buy(
      tokenId,
      user,
      issuingTime,
      nonce,
      auth
    );
    dispatch(
      addPendingTransaction({
        txHash,
        txMethod,
        params: {
          tokenId,
          account: user,
        },
      })
    );
  };

  const buyTx = usePendingTransaction({
    txMethod: TX_BUY,
    params: { tokenId, account },
  });

  return (
    <Modal close={stopBuying}>
      <h1>Buy</h1>
      {buyTx.isPending ? (
        <p>{translate("Buyable.pending")}</p>
      ) : !buyToken ? (
        <Fragment>
          {!isEmailError ? (
            <Form>
              <FormEmail address={account} tokenId={parseInt(tokenId)} />
              <ButtonSubmit />
            </Form>
          ) : (
            <Fragment>
              <p>{translate("Detail.emailError")}</p>
              <p>{translate("Detail.emailError2")}</p>
            </Fragment>
          )}
        </Fragment>
      ) : buyTx.isSuccess ? (
        <Fragment>
          <p>{translate("Buyable.confirmation", { name, account })}</p>
          <p>{translate("Buyable.confirmation2", { email })}</p>
        </Fragment>
      ) : buyTx.isError ? (
        <p>{translate("FormEmail.error")}</p>
      ) : (
        <Fragment>
          <p>{translate("Buyable.youCanBuyNow")}</p>
          <p>{translate("Buyable.youCanBuyNow2", { name })}</p>
          <button onClick={() => onClickBuy(buyToken, account)}>Confirm</button>
        </Fragment>
      )}
    </Modal>
  );
};

Buyable.propTypes = {
  name: PropTypes.string.isRequired,
  stopBuying: PropTypes.func.isRequired,
  tokenId: PropTypes.string.isRequired,
};

export default Buyable;
